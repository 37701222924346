<p-toast
  key="toast-feedback-request"
  [showTransformOptions]="'translateY(100%)'"
  [showTransitionOptions]="'1000ms'"
  [hideTransitionOptions]="'2000ms'"
  [showTransformOptions]="'translateX(100%)'"
  [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
/>
<ngx-spinner name="spinnerFullScreen" [fullScreen]="true" type="square-loader" size="medium" bdColor="rgba(4,2,253,0.3)"><p style="color: white" >Carregando...</p></ngx-spinner>
<p-toast />
@if (isLoggedIn$ | async) {
<div class="flex">
  <div class="flex-initial">
    <app-menu-sidebar />
  </div>
  <div class="flex-auto bg-theme__main-content">
    <div class="flex flex-column">
      <div class="flex-grow-1 mx-2 mt-2 md:mx-4 md:mt-4">
        <app-navbar-top />
      </div>
      <div class="m-2 md:m-4"><router-outlet /></div>
    </div>
  </div>
</div>
} @else {
<div class="flex">
  <div class="flex-auto">
    <router-outlet />
  </div>
</div>
}
