import { Component, DestroyRef, inject } from '@angular/core';
import { UserJwtPayload } from '../../../shared/interfaces/user-jwt';
import { AuthService } from '../../../shared/services/auth.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-navbar-top',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './navbar-top.component.html',
  styleUrl: './navbar-top.component.scss',
})
export class NavbarTopComponent {
  selectedDate: string = 'Hoje';

  datesOptions = [
    { name: 'Hoje' },
    { name: 'Semana' },
    { name: 'Mês' },
    { name: 'Mês anterior' },
    { name: 'Ano' },
  ];
  user: UserJwtPayload | null = null;
  userPhoto: string = 'assets/images/photo_profile_default.png';

  #authService = inject(AuthService);
  #logger = inject(LoggerService);

  ngOnInit() {
    this.user = this.#authService.userSnapshot;
    this.#logger.info('NavbarTopComponent: ', 'User by token jwt\n', this.user);
    if (!this.user) {
      this.#logger.error('NavbarTopComponent: ', 'User by token jwt not found\n');
      this.#authService.logout();
    }
    this._getUserPhoto();
  }

  private _getUserPhoto() {
    this.#authService.getUserFullData().subscribe({
      next: (data) => {
        if (data?.photo) {
          if (data?.photo.startsWith('data:image/')) {
            this.userPhoto = data?.photo;
          } else if (this._isBase64(data?.photo)) {
            this.userPhoto = `data:image/jpeg;base64,${data?.photo}`;
          } else {
            this.userPhoto = 'assets/images/photo_profile_default.png';
          }
        }

        this.#logger.info('NavbarTopComponent: ', 'User\n', data);
      },
      error: (error) => {
        this.#logger.error('NavbarTopComponent: ', 'User not found\n', error);
      },
    });
  }

  private _isBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  }
}
