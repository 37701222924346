import { Component, DestroyRef, afterNextRender, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { MenuSidebarComponent } from './core/components/menu-sidebar/menu-sidebar.component';
import { NavbarTopComponent } from './core/components/navbar-top/navbar-top.component';
import { AuthService } from './shared/services/auth.service';
import { LanguageService } from './shared/services/language.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { LoggerService } from './shared/services/logger.service';
import { SharedModule } from './shared/shared.module';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [SharedModule, MenuSidebarComponent, NavbarTopComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'FastFisio | ' + environment.mode;

  #titleService = inject(Title);
  #primengConfig = inject(PrimeNGConfig);
  #translateService = inject(TranslateService);
  #authService = inject(AuthService);
  #languageService = inject(LanguageService);
  #localStrorageService = inject(LocalStorageService);
  #destroyRef = inject(DestroyRef);
  #logger = inject(LoggerService);
  #messageService = inject(MessageService);

  isLoggedIn$ = new Observable<boolean>;
  userLang = this.#translateService.getBrowserCultureLang() || 'pt-BR';

  constructor() {
    afterNextRender(() => {
      // Define o idioma da aplicação com base no idioma do navegador.
      this._setLanguage(this.userLang);
    });
  }

  /**
   * Método executado quando o componente é inicializado.
   * Define o título da aplicação com base no modo de desenvolvimento e
   * configura o idioma e a configuração do PrimeNG.
   */
  ngOnInit(): void {
    // Define o título da aplicação com base no modo de desenvolvimento.
    if (environment.mode === 'development') {
      this.#titleService.setTitle(this.title);
    }

    // Configura a configuração do PrimeNG.
    this._setPrimeNGConfig();

    const token = this.#localStrorageService.getAuthToken();
    if (token) {
     this.#authService.loadUser(token);
    }
    // Verifica se o usuário está logado.
    this.isLoggedIn$ = this.#authService.isLoggedIn;
  }

  /**
   * Define a configuração do PrimeNG.
   *
   * Habilita o efeito ripple e define o idioma padrão para Portuguese (Brazil).
   */
  private _setPrimeNGConfig(): void {
    this.#primengConfig.ripple = true; // Habilita o efeito ripple
  }

  /**
   * Define o idioma da aplicação.
   *
   * @param lang - Código do idioma (pt-br, en-us, etc).
   */
  private _translatePrimeNG(lang: string): void {
    this.#translateService.use(lang);

    // Define o idioma padrão da biblioteca PrimeNG.
    //
    // O método `get` retorna um objeto contendo todas as chaves e valores
    // traduzidos para o idioma atual.
    this.#translateService.get('primeng').pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(res => {
      // Método `setTranslation` da PrimeNGConfig é responsável por definir
      // o idioma padrão para a biblioteca.
      this.#primengConfig.setTranslation(res);
    });
  }

  /**
   * Define o idioma da aplicação.
   *
   * Busca pelo idioma do navegador e armazena o ID do idioma no Local Storage
   * para futuramente carregar as informações corretas.
   *
   * @param lang - Código do idioma (pt-br, en-us, etc).
   */
  private _setLanguage(lang: string): void {
    // Define o idioma padrão dos componentes primeNG
    this._translatePrimeNG(this.userLang.toLowerCase());

    // Define o idioma padrão da aplicação. Busca pelo idioma do navegador.
    this.#translateService.setDefaultLang(lang.toLowerCase());

    // Busca o ID do idioma no servidor
    this.#languageService.getLanguageID(lang).subscribe(
      {
        // Se a busca for bem-sucedida, armazena o ID do idioma no Local Storage
        next: (response) => {
          this.#localStrorageService.addLanguageID(response.id);
        },
        // Se a busca falhar, armazena o ID do idioma padrão da aplicação e exibe o erro
        error: (error) => {
          this.#localStrorageService.addLanguageID(environment.languageId);
          this.#logger.error('AppComponent: ', 'Falha ao buscar o idioma\n', error);
        }
      }
    );
  }
}
