import { Location } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedModule } from '../../../shared/shared.module';
import { AuthService } from '../../../shared/services/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Role } from '../../../shared/enums/role';

@Component({
  selector: 'app-menu-sidebar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './menu-sidebar.component.html',
  styleUrl: './menu-sidebar.component.scss',
})
export class MenuSidebarComponent implements OnInit {

  showMenu: string = '';
  menu_collapsed: boolean = true;
  userRole: string | null = null;
  role: typeof Role = Role;

  event$: any = new Observable();

  #current_router_location = inject(Location);
  #router = inject(Router);
  #authService = inject(AuthService);
  #destroyRef = inject(DestroyRef);


  navbar_dropdown_items_admin = [
    {
      label: 'Cadastro de Empresa',
      icon: 'icon-svg icon-lamp icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/users/establishment/new']);
      },
    },
    {
      label: 'Cadastro de Serviços',
      icon: 'icon-svg icon-term icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/services/new']);
      },
    },
    {
      label: 'Cadastro de Profissional',
      icon: 'icon-svg icon-person-plus icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/admin/users/professional/new']);
      },
    },
  ];

  navbar_dropdown_items_professional = [
    {
      label: 'Cadastrar Serviços',
      icon: 'icon-svg icon-box icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/register/service/new']);
      },
    },
    {
      label: 'Cadastrar Produtos',
      icon: 'icon-svg icon-package icon__menu-dropdown',
      command: () => {
        this.#router.navigate(['/professional/register/product/new']);
      },
    }
  ];

  ngOnInit() {
    this.event$ = this.#current_router_location.onUrlChange((current_route) => {
      this.showMenu = current_route;
    });

    this.userRole = this.#authService.userSnapshot!.role;
  }

  logout() {
    this.#authService.logout();
  }
}
